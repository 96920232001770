import React from 'react';
import { fetchUtils, Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import Dashboard from './View/Dashboard';

// , ListGuesser, EditGuesser
//import BulletIcon from '@material-ui/icons/FormatListBulleted';

import authProvider from "./authProvider";
import { BulletList, BulletShow } from "./View/bullets";
import  { SiteList, SiteEdit, SiteCreate } from "./View/sites";
import  { UserList, UserEdit, UserCreate } from "./View/users";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    if (localStorage.getItem('token')) {
        const token = localStorage.getItem('token');
        options.headers.set('Authorization', `Bearer ${token}`);
    }
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider('https://estate-calc.ru/api', httpClient);

const App = () => (
    <Admin dataProvider={dataProvider} authProvider={authProvider} dashboard={Dashboard} title="Estate-Calc.ru">
        {role => [
         <Resource name="bullets" list={BulletList} show={BulletShow} options={{ label: 'Объявления' }} />,
         <Resource name="sites" list={SiteList} edit={SiteEdit} create={SiteCreate} options={{ label: 'Сайты' }} />,
         <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} options={{ label: 'Пользователи' }} />,
         <Resource name="stat/field/district" />,
         <Resource name="stat/field/admRajon" />,
         <Resource name="stat/field/flatType" />,
        ]}
    </Admin>
);

export default App;
