import React from 'react';
import { Pagination, List, Show, Datagrid, TextField, ReferenceField, DateField, NumberField, TabbedShowLayout, Tab } from 'react-admin';
import { ReferenceInput, SelectInput, RichTextField, ArrayField, DateInput, Filter, SearchInput, NumberInput } from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const NameUrlField = props => (
        <a className="RaLink-link-275" href={props.record.url}><span className="MuiTypography-root RaReferenceField-link-252 MuiTypography-body2">{props.record.name}</span></a>
)

const PriceField = props => (
      <span><NumberField source="price" {...props} />&nbsp;&#8381;</span>
)

const FloorChoices = [
    { id: '', name: '' },
    { id: 'first', name: 'Первый' },
    { id: 'low', name: 'Низкий (1 или 2)' },
    { id: 'middle', name: 'Средний' },
    { id: 'last', name: 'Последний' },
];

const useQuickFilterStyles = makeStyles(theme => ({
    chip: {
        marginBottom: theme.spacing(1),
    },
}));
const QuickFilter = ({ label }) => {
//    const translate = useTranslate();
    const classes = useQuickFilterStyles();
    return <Chip className={classes.chip} label={label} />;
};

const ListFilters = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <QuickFilter source="actuality" label="Актуальное" defaultValue={true} />
        <QuickFilter source="3sigma" label="3-сигма" defaultValue={true} />
        <ReferenceInput source="sites_id" label="Сайт" reference="sites">
        <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Тип квартиры" source="flatType" reference="stat/field/flatType">
        <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Микрорайон" source="district" reference="stat/field/district">
        <SelectInput optionText="name" />
        </ReferenceInput>
        {/*<ReferenceInput label="Район" source="admRajon" reference="stat/field/admRajon">*/}
        {/*<SelectInput optionText="name" />*/}
        {/*</ReferenceInput>*/}
        <SelectInput source="floor" label="Этаж" choices={FloorChoices} />
        <NumberInput source="area_gte" label="Площадь от" min={1} max={1000}/>
        <NumberInput source="area_lte" label="Площадь до" min={1} max={1000}/>
        <NumberInput source="price_m_gte" label="Цена м2 от" min={1} max={9000000}/>
        <NumberInput source="price_m_lte" label="Цена м2 до" min={1} max={9000000}/>
        <NumberInput source="price_gte" label="Цена от" min={1} max={900000000}/>
        <NumberInput source="price_lte" label="Цена до" min={1} max={900000000}/>
        <DateInput source="date_gte" label="Дата с" />
        <DateInput source="date_lte" label="Дата по" />
    </Filter>
);
//

export const BulletList = props => (
    <List {...props} perPage={25} filters={<ListFilters />} pagination={<PostPagination />}>
        <Datagrid rowClick="show">
            <TextField source="id" label="№" />
            <DateField source="created_at" label="Дата публикации" />
            <NumberField source="dayOnSite" label="Дней" />
            <ReferenceField source="sites_id" reference="sites" label="Сайт"><TextField source="name" /></ReferenceField>
            <NameUrlField source="url" name="name" label="Ссылка на сайт" />
            <PriceField source="price" label="Цена" />
            <TextField source="district" label="Микрорайон" />
            <TextField source="address" label="Адрес" />
            <TextField source="flatType" label="Тип квартиры" />
            <NumberField source="area_t" label="Площадь" />
            <PriceField source="price_m" label="Цена м2" />
        </Datagrid>
    </List>
);


export const BulletShow = props => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Объявление">
            <TextField source="id" label="Номер" />
            <NameUrlField source="url" name="name" label="Ссылка на сайт" />
            <ReferenceField source="sites_id" reference="sites" label="Сайт" ><TextField source="name" /></ReferenceField>
            <DateField source="created_at" label="Дата публикации" />
            <DateField source="updated_at" label="Дата последнего обновления" />
            <NumberField source="dayOnSite" label="Дней на сайте" />
            <TextField source="admRajon" label="Район"/>
            <TextField source="district" label="Микрорайон"/>
            </Tab>
            <Tab label="Объект" path="object">
            <TextField source="realtyRepair" label="Ремонт"/>
            <TextField source="wallMaterial" label="Материал стен"/>
            <TextField source="floorNo" label="Этаж"/>
            <TextField source="floorTotal" label="Всего этажей"/>
            <TextField source="buildYear" label="Год постройки"/>
            <TextField source="flatType" label="Тип квартиры" />
            </Tab>
            <Tab label="Стоимость" path="price">
            <PriceField source="price_m" label="Цена м2" />
            <NumberField source="area_t" label="Площадь м2"/>
            <ArrayField source="price_history" label="Цена квартиры" >
                <Datagrid>
                    <DateField source="updated_at" label="Дата" />
                    <PriceField source="price" label="Цена" />
                </Datagrid>
            </ArrayField>
            </Tab>
            <Tab label="Описание" path="desc">
            <RichTextField source="body" label="Описание" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default '';
