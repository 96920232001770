import React from 'react';
import { Create, Edit, SimpleForm, TextInput, BooleanInput, NumberInput  } from 'react-admin';
import { List, Datagrid, TextField, NumberField, UrlField, BooleanField  } from 'react-admin';
import {required, minLength, maxLength,number, minValue, maxValue  } from 'react-admin';

const validatePassword = [required(), minLength(3), maxLength(30)];
const validateUrl = [ required(), minLength(3), maxLength(120)];
//const validateReq = [ required() ];
const validateNumber = [ required(), number(), minValue(1), maxValue(10) ];

export const SiteList = props => (
    <List {...props} perPage={10}>
        <Datagrid rowClick="edit">
            <NumberField source="id" label="№" />
            <TextField source="name" label="Сайт" />
            <TextField source="town" label="Город" />
            <UrlField source="url" label="Ссылка" />
            <TextField source="pages" label="Страниц" />
            <BooleanField source="is_enable" label="Активно" />
        </Datagrid>
    </List>
);


export const SiteEdit = props => (
    <Edit {...props}>
        <SimpleForm  redirect="list">
            <TextInput disabled source="id" label="№" />
            <TextInput source="name" validate={validatePassword} label="Сайт" />
            <TextInput source="town" validate={validatePassword} label="Город" />
            <TextInput source="url" validate={validateUrl} label="Сылка на список" />
            <NumberInput source="pages" validate={validateNumber} label="Страниц использовать" />
            <BooleanInput source="is_enable" label="Активно" />
        </SimpleForm>
    </Edit>
);

export const SiteCreate = props => (
    <Create {...props} redirect="list">
        <SimpleForm  redirect="view" >
            <TextInput source="name" validate={validatePassword} label="Сайт" />
            <TextInput source="town" validate={validatePassword} label="Город" />
            <TextInput source="url" validate={validateUrl} label="Ссылка на список" />
            <NumberInput source="pages" initialValue="1" validate={validateNumber} label="Страниц использовать" />
            <BooleanInput source="is_enable" label="Активно" />
        </SimpleForm>
    </Create>
);


export default '';
