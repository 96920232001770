import React from 'react';
import { Create, Edit, SimpleForm, TextInput, BooleanInput, SelectInput, NumberField    } from 'react-admin';
import { List, Datagrid, TextField, EmailField, BooleanField, PasswordInput  } from 'react-admin';
import {required, minLength, maxLength, email  } from 'react-admin';

const choices=[
{ id: 'guest', name: 'Гость', default: true },
{ id: 'user', name: 'Пользователь' },
{ id: 'admin', name: 'Администратор' },
]



const validatePassword = [required(), minLength(3), maxLength(20)];
const validateEmail = [ required(), email() ];
const validateReq = [ required() ];

export const UserList = props => (
    <List {...props} perPage={10}>
        <Datagrid rowClick="edit">
            <NumberField source="id" label="№" />
            <TextField source="name" label="Пользователь" />
            <EmailField source="email" label="Е-мейл" />
            <TextField source="role" label="Роль" />
            <BooleanField source="is_enable" label="Активен" />
        </Datagrid>
    </List>
);

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm  redirect="list">
            <TextInput disabled source="id" label="№" />
            <TextInput source="name" validate={validatePassword} label="Пользователь" />
            <TextInput source="email" validate={validateEmail} label="Е-мейл" />
            <PasswordInput source="password" label="Пароль" />
            <SelectInput  label="Роль" source="role" choices={choices} validate={validateReq} />
            <BooleanInput source="is_enable" label="Активен" />
        </SimpleForm>
    </Edit>
);

export const UserCreate = props => (
    <Create {...props} redirect="list">
        <SimpleForm redirect="list">
            <TextInput source="name" validate={validatePassword} label="Пользователь" />
            <TextInput source="email" validate={validateEmail} label="Е-мейл" />
            <PasswordInput source="password" validate={validatePassword} label="Пароль" />
            <SelectInput label="Роль" source="role" choices={choices}  initialValue="guest" validate={validateReq} />
            <BooleanInput source="is_enable" label="Активен" />
        </SimpleForm>
    </Create>
);


export default '';
