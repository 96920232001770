//import React from 'react';
//import * as jwtDecode from 'jwt-decode';

const authProvider = {
    login: ({ username, password }) =>  {
        const request = new Request('https://estate-calc.ru/api/auth/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ access_token, role, expires_in }) => {
                const expires_at = (new Date().getTime()/1000) + expires_in;
                localStorage.setItem('token', access_token);
                localStorage.setItem('role', role);
                localStorage.setItem('expires_at', expires_at );
//                const decoded: string = jwtDecode(access_token);
//                console.log('decoded=' + JSON.stringify(decoded));

            });
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('expires_at' );
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
//    checkAuth: () => localStorage.getItem('token')  ? Promise.resolve() : Promise.reject(),
    checkAuth: (params) => {
        const token = (localStorage.getItem('token'))?localStorage.getItem('token'):null;
        const expires_at = (localStorage.getItem('expires_at'))?localStorage.getItem('expires_at'):null;
        if (token) {
            if (expires_at > (new Date().getTime() / 1000)) {
                return Promise.resolve();
            } else {
                const request = new Request('https://estate-calc.ru/api/auth/refresh', {
                    method: 'POST',
                    body: JSON.stringify(''),
                    headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }),
               });
            const response = fetch(request)
                    .then(response => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then(({ access_token , expires_in }) => {
                        const expires_at = (new Date().getTime()/1000) + expires_in;
                        localStorage.setItem('token', access_token);
                        localStorage.setItem('expires_at', expires_at );
                        return Promise.resolve();
                    });

                return response;
            }
        }
        return Promise.reject();
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider;
